import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Banner from '../components/Banner'
import SEO from '../components/seo'

class Home extends React.Component {
  render() {
    return (
      <Layout>
        <SEO
          title='The Web Design Company With A Difference'
          description='A website design company with a difference. We build websites for the love of building websites.'
          keywords={[
            'web design',
            'web development',
            'online marketing',
            'website',
            'website design',
          ]}
        />

        <Banner />

        <div id='main'>
          <section id='one' className='tiles'>
            <article>
              <header className='major'>
                <h3>Website Design.</h3>
                <p>
                  Anyone can design a good-looking website. But looks aren't
                  everything.
                </p>
              </header>
              <Link
                to='/services/'
                className='link primary'
                aria-label='Web Design Services'
              ></Link>
            </article>
            <article>
              <header className='major'>
                <h3>You Need A Converting Website.</h3>
                <p>
                  A website that turns heads is one thing - but you want
                  results. We develop websites that turn visitors into buyers.
                </p>
              </header>
              <Link
                to='/services/'
                className='link primary'
                aria-label='Online Marketing Services'
              ></Link>
            </article>
            <article>
              <header className='major'>
                <h3>Your Website Should Solve Your Problem.</h3>
                <p>
                  We identify the goal you want to achieve. Then we plan your
                  web design to move visitors towards the goal.
                </p>
              </header>
              <Link
                to='/services/'
                className='link primary'
                aria-label='Web Design and Online Marketing Services'
              ></Link>
            </article>
            <article>
              <header className='major'>
                <h3>Simplicity Works.</h3>
                <p>
                  Often the simplest web design achieves the greatest results.
                </p>
                <p>"More white, less shite."</p>
              </header>
              <Link
                to='/portfolio/'
                className='link primary'
                aria-label='Web Design Portfolio'
              ></Link>
            </article>
            <article>
              <header className='major'>
                <h3>More Than Web Designers.</h3>
                <p>
                  Most website developers can design beautiful sites. More
                  importantly? Your site should do what it was built to do -
                  make you money.
                </p>
              </header>
              <Link
                to='/services/'
                className='link primary'
                aria-label='Online Marketing Services'
              ></Link>
            </article>
            <article>
              <header className='major'>
                <h3>Finding The Right Web Design Company.</h3>
                <p>
                  Websites have to look good, and do the job they're ultimately
                  designed for.
                </p>
                <p>
                  Find a company that has vision, passion for building websites,
                  and up-to-date web development skills.
                </p>
              </header>
              <Link
                to='/about/'
                className='link primary'
                aria-label='About'
              ></Link>
            </article>
          </section>
          <section id='two'>
            <div className='inner'>
              <header className='major'>
                <h2>Websites That Generate Business.</h2>
              </header>
              <p>You have the savvy, we have the vision.</p>
              <p>
                We offer beautiful custom web design, cutting-edge web
                development, and tailor-made online marketing packages.
              </p>
              <p>
                We are detail-oriented, business-minded, and develop websites
                that make you more money.
              </p>
              <p>Let’s collaborate.</p>
              <ul className='actions'>
                <li>
                  <a
                    href='#contact'
                    className='button next scrolly'
                    aria-label='Get Started'
                  >
                    Get Started
                  </a>
                </li>
                <li>
                  <Link
                    to='/services/'
                    className='button next special'
                    aria-label='Web Design and Online Marketing Services'
                  >
                    See Our Services
                  </Link>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Home
