import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

export default Banner => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "black-alsatian-pup.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 180, maxHeight: 447) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <section id='banner' className='major'>
      <div className='inner'>
        <header className='major'>
          <h1>Web Design.</h1>
        </header>
        <div className='content'>
          <p>The one thing no-one tells you.</p>
          <ul className='actions'>
            <li>
              <a
                href='#one'
                className='button next scrolly'
                aria-label='Find Out'
              >
                Find Out
              </a>
            </li>
          </ul>
        </div>
        <div className='image'>
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt='Black Alsatian Pup'
          />
        </div>
      </div>
    </section>
  )
}
